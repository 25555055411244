import { FunctionComponent } from "react";
import styled from "styled-components";

const Div = styled.div`
  position: relative;
  font-size: var(--font-size-13xl);
  color: var(--color-darkcyan);
`;
const Image1Icon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 358.5px;
  flex-shrink: 0;
  object-fit: cover;
`;
const Div1 = styled.div`
  position: relative;
`;
const Image1Parent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const Image3Icon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;
const Image4Parent = styled.div`
  width: 240.5px;
  height: 364px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const Image3Parent = styled.div`
  width: 740.5px;
  height: 380.5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
const FrameParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
`;
const Image5Icon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 375px;
  flex-shrink: 0;
  object-fit: cover;
`;
const Image7Icon = styled.img`
  position: relative;
  width: 412px;
  height: 309.2px;
  object-fit: cover;
`;
const Image7Parent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
const ParentRoot = styled.div`
  align-self: stretch;
  border-bottom: 1px solid var(--color-darkcyan);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-21xl) 0px;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-black);
  font-family: var(--font-noto-sans-hk);
`;

const Button = styled.button`
  appearance: none;
  background-color: #1b9991;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;

  :focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  :hover {
    background-color: #2c974b;
  }

  :focus {
    box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
    outline: none;
  }

  :disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, 0.1);
    color: rgba(255, 255, 255, 0.8);
    cursor: default;
  }

  :active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
  }
`;

const SectionCard: FunctionComponent = () => {
  return (
    <ParentRoot data-scroll-to="frameContainer2">
      <Div>2024 活動</Div>
      <FrameParent>
        <Image1Parent>
          <img
            alt=""
            src="/2023機械人搶包山大賽.jpg"
            style={{
              alignSelf: "stretch",
              position: "relative",
              maxWidth: "100%",
              overflow: "hidden",
              height: "700px",
              flexShrink: "0",
              objectFit: "cover",
            }}
          />
          <Div1>2023機械人搶包山大賽（香港站）</Div1>
          <Div1>
            <Button
              onClick={() => {
                window.open(
                  "https://drive.google.com/file/d/18WQ-EO6BpQH25qJSHQfHgVxXEz7Lu6BV/view?usp=sharing",
                  "_blank"
                );
              }}
            >
              小學組賽規
            </Button>{" "}
            <Button
              onClick={() => {
                window.open(
                  "https://drive.google.com/file/d/1YS0I5ryhhBEfAXtUDJ-trZ75kJo3SVtM/view?usp=sharing",
                  "_blank"
                );
              }}
            >
              中學組賽規
            </Button>
          </Div1>
        </Image1Parent>
      </FrameParent>
      <Div>2023 活動</Div>
      <FrameParent>
        <Image1Parent>
          <Image1Icon alt="" src="/image-1@2x.png" />
          <Div1>百年樹人冰球比賽</Div1>
        </Image1Parent>
        <Image3Parent>
          <Image3Icon alt="" src="/image-3@2x.png" />
          <Image4Parent>
            <Image3Icon alt="" src="/image-4@2x.png" />
            <Image3Icon alt="" src="/image-2@2x.png" />
          </Image4Parent>
        </Image3Parent>
      </FrameParent>
      <FrameParent>
        <Image1Parent>
          <Image5Icon alt="" src="/image-5@2x.png" />
          <Div1>長幼共樂慶中秋</Div1>
        </Image1Parent>
        <Image7Parent>
          <Image7Icon alt="" src="/image-7@2x.png" />
          <Image7Icon alt="" src="/image-6@2x.png" />
        </Image7Parent>
      </FrameParent>
    </ParentRoot>
  );
};

export default SectionCard;
