import { FunctionComponent } from "react";
import styled from "styled-components";

const Div = styled.div`
  position: relative;
`;
const LogLogo1 = styled.img`
  position: relative;
  width: 492px;
  height: 295.3px;
  object-fit: cover;
  @media screen and (max-width: 960px) {
    width: 400px;
    height: 200px;
  }
`;
const OcbcBankHk = styled.span``;
const OcbcBankHkLimited = styled.p`
  margin: 0;
`;
const Hkd0358024153770011 = styled.b``;
const Hkd035802415377001 = styled.p`
  margin: 0;
  font-size: var(--font-size-5xl);
`;
const LogLogo1Parent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--font-size-xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: var(--gap-21xl);
  }
`;
const Parent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const MacbookAir1InnerRoot = styled.div`
  align-self: stretch;
  background-color: var(--color-mediumturquoise);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-61xl);
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-white);
  font-family: var(--font-noto-sans-hk);
`;

const DonationForm: FunctionComponent = () => {
  return (
    <MacbookAir1InnerRoot data-scroll-to="frameContainer">
      <Parent1>
        <Div>捐款</Div>
        <LogLogo1Parent>
          <LogLogo1 alt="" src="/log-logo-12@2x.png" />
          <Div>
            <OcbcBankHkLimited>
              <OcbcBankHk>
                <OcbcBankHk>{`OCBC Bank (HK) Limited: `}</OcbcBankHk>
              </OcbcBankHk>
            </OcbcBankHkLimited>
            <Hkd035802415377001>
              <OcbcBankHk>
                <Hkd0358024153770011>
                  HKD 035-802-415377-001
                </Hkd0358024153770011>
              </OcbcBankHk>
            </Hkd035802415377001>
          </Div>
        </LogLogo1Parent>
      </Parent1>
    </MacbookAir1InnerRoot>
  );
};

export default DonationForm;
