import { FunctionComponent, useCallback } from "react";
import styled from "styled-components";
import SectionCard from "../components/SectionCard";
import DonationForm from "../components/DonationForm";

const LogLogo1 = styled.img`
  position: relative;
  width: 66.6px;
  height: 40px;
  object-fit: cover;
`;
const Div = styled.div`
  position: relative;
  cursor: pointer;
`;
const Div1 = styled.div`
  position: relative;
`;
const Parent1 = styled.div`
  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
const LogLogo1Parent = styled.div`
  align-self: stretch;
  border-bottom: 1px solid var(--color-mediumturquoise);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px var(--padding-21xl);
`;
const LogLogo11 = styled.img`
  position: relative;
  width: 490px;
  height: 244px;
  object-fit: cover;
  @media screen and (max-width: 960px) {
    width: 400px;
    height: 200px;
  }
`;
const LeagueOfOrdinary = styled.p`
  margin: 0;
`;
const LeagueOfOrdinaryContainer = styled.div`
  flex: 1;
  position: relative;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const LogLogo1Group = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-91xl);
  gap: 80px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: var(--gap-21xl);
  }
`;
const MacbookAir1Inner = styled.div`
  align-self: stretch;
  border-bottom: 1px solid var(--color-darkcyan);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-91xl) 0px;
  color: var(--color-black);
  @media screen and (max-width: 960px) {
    padding-top: var(--padding-21xl);
    padding-bottom: var(--padding-21xl);
    box-sizing: border-box;
  }
`;
const Image9Icon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;
const Image9Parent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const Group = styled.div`
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-21xl) 0px 0px;
  gap: var(--gap-xl);
  cursor: pointer;
  font-size: var(--font-size-13xl);
`;
const Container = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-21xl) 0px 0px;
  gap: var(--gap-xl);
  font-size: var(--font-size-13xl);
`;
const FrameDiv = styled.div`
  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const LeagueOfOrdinaryGentlemenParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: center;
  }
`;
const MacbookAir1Child = styled.div`
  align-self: stretch;
  background-color: var(--color-darkcyan);
  height: 90px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 27px var(--padding-21xl);
  box-sizing: border-box;
  color: var(--color-white);
`;
const MacbookAir1Root = styled.div`
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-darkcyan);
  font-family: var(--font-noto-sans-hk);
`;

const MacBookAir1: FunctionComponent = () => {
  const onTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer3']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText2Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer2']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText3Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText4Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onFrameContainer4Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer4']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start" });
    }
  }, []);

  const onText11Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer3']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText13Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer2']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText14Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onText15Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <MacbookAir1Root>
      <LogLogo1Parent>
        <LogLogo1 alt="" src="/log-logo-1@2x.png" />
        <Parent1>
          <Div onClick={onTextClick}>關於本會</Div>
          <Div1>管治及管理架構</Div1>
          <Div onClick={onText2Click}>活動</Div>
          <Div onClick={onText3Click}>新聞及資訊</Div>
          <Div onClick={onText4Click}>捐款</Div>
        </Parent1>
      </LogLogo1Parent>
      <MacbookAir1Inner data-scroll-to="frameContainer3">
        <LogLogo1Group>
          <LogLogo11 alt="" src="/log-logo-11@2x.png" />
          <LeagueOfOrdinaryContainer>
            <LeagueOfOrdinary>
              「百年樹人會」（League of Ordinary
              Gentlemen）於2005年成立，為本港註冊非牟利慈善機構，以「培育幼苗，回饋社群」為宗旨。該會擁有逾百名會員，眾會員都是在社會獨當一面的專業或成功人士，所有會員均為男士。他們希望以個人的能力、經驗，或在業界的影響力，幫助有需要人士。主要使命是幫助國內貧困家庭的孩童得到基本及良好的教育，以及為香港青少年提供擴闊生活視野及文化交流的機會。
            </LeagueOfOrdinary>
            <LeagueOfOrdinary>&nbsp;</LeagueOfOrdinary>
            <LeagueOfOrdinary>
              過去，「百年樹人會」曾參與的慈善活動包括：籌集超過50萬港元捐助興建2所分別位於貴州和雲南省的「三穗縣百年樹人台烈小學」及「永平縣水泄鄉百年樹人會希望小學」；捐贈100台電腦予雲南省的「允景洪小學」；籌募善款逾7萬港元，透過紅十字會幫助四川地震受災人士；與及送贈數千件禦寒衣物予雲南省楚雄巿山區受泥石流影響的災民等等。
            </LeagueOfOrdinary>
          </LeagueOfOrdinaryContainer>
        </LogLogo1Group>
      </MacbookAir1Inner>
      <SectionCard />
      <Group data-scroll-to="frameContainer4" onClick={onFrameContainer4Click}>
        <Div1>過往活動</Div1>
        <Image9Parent>
          <Image9Icon alt="" src="/image-9@2x.png" />
          <Image9Icon alt="" src="/image-10@2x.png" />
          <Image9Icon alt="" src="/image-11@2x.png" />
          <Image9Icon alt="" src="/image-12@2x.png" />
          <Image9Icon alt="" src="/image-13@2x.png" />
          <Image9Icon alt="" src="/image-14@2x.png" />
        </Image9Parent>
      </Group>
      <Container data-scroll-to="frameContainer1">
        <Div1>新聞及資訊</Div1>
        <Image9Icon alt="" src="/image-15@2x.png" />
      </Container>
      <DonationForm />
      <MacbookAir1Child>
        <LeagueOfOrdinaryGentlemenParent>
          <Div1>© 2023 League of Ordinary Gentlemen. All rights reserved.</Div1>
          <FrameDiv>
            <Div onClick={onText11Click}>關於本會</Div>
            <Div1>管治及管理架構</Div1>
            <Div onClick={onText13Click}>活動</Div>
            <Div onClick={onText14Click}>新聞及資訊</Div>
            <Div onClick={onText15Click}>捐款</Div>
          </FrameDiv>
        </LeagueOfOrdinaryGentlemenParent>
      </MacbookAir1Child>
    </MacbookAir1Root>
  );
};

export default MacBookAir1;
