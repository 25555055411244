import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    }
:root {

/* fonts */
--font-noto-sans-hk: 'Noto Sans HK';

/* font sizes */
--font-size-xs: 12px;
--font-size-5xl: 24px;
--font-size-xl: 20px;
--font-size-13xl: 32px;

/* Colors */
--color-white: #fff;
--color-darkcyan: #1b9991;
--color-mediumturquoise: #24cabf;
--color-black: #000;

/* Gaps */
--gap-3xs: 10px;
--gap-21xl: 40px;
--gap-xl: 20px;
--gap-9xs: 4px;

/* Paddings */
--padding-21xl: 40px;
--padding-61xl: 80px;
--padding-91xl: 110px;

}
`;
